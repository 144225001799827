<template>
    <div>
        <form @submit.prevent="next()" method="post">
            <!-- 1st row -->
            <b-row class="mb-6 mt-4">

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="emailDisabled" :class="{ 'disabled': emailDisabled }">E-mail
                            <span style="color: red;">*</span>
                        </Label>
                    </b-row>

                    <input class="form-control-field" name="email" type="email" placeholder="Type your answer here..." @input="setData"
                        v-model="emailInput" @blur="handleEmailBlur" :loading="emailchecking" required :disabled="emailDisabled" :class="{ 'disabled': emailDisabled }"/>
                    <i v-if="emailchecking" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    <i v-if="emailDisabled" class="fa fa-check" aria-hidden="true"></i>
                </b-col>

            </b-row>

            <!-- 2nd row -->
            <b-row class="mb-6 mt-4">

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">First Name <span style="color: red;">*</span></Label>
                    </b-row>

                    <input class="form-control-field" name="firstName" type="text" @input="setData" :disabled="fnameDisabled" :class="{ 'disabled': fnameDisabled }"
                        placeholder="Type your answer here..." v-model="firstName" spanText="(as displayed on an ID)" required/>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Last Name <span style="color: red;">*</span></Label>
                    </b-row>

                    <input class="form-control-field" name="lastName" type="text" placeholder="Type your answer here..." :disabled="lnameDisabled" :class="{ 'disabled': lnameDisabled }"
                        v-model="lastName" spanText="(as displayed on an ID)" @input="setData" required/>
                </b-col>
            </b-row>

            <!-- 3rd row -->
            <b-row class="mt-4">
                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Gender <span style="color: red;">*</span></Label>
                    </b-row>

                    <div class="text-field w-100" :class="{ 'disabled': genderDisabled }">
                        <div class="input-container position-relative">
                            <v-select class="form-control-field" name="gender" type="single" :options="genderOptions" @input="setData"
                                v-model="selectedGender" placeholder="Type to search or select" required :disabled="genderDisabled"/>
                        </div>
                    </div>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Date of Birth <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100" :class="{ 'disabled': dobDisabled }">
                        <input class="form-control-field" name="dateOfBirth" type="date" @input="setData" :disabled="dobDisabled"
                            placeholder="Type your answer here..." v-model="dateOfBirth" onfocus="this.showPicker()" required/>
                    </div>
                </b-col>
            </b-row>

            <!-- 4th row -->
            <b-row class="mt-4">
                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Post code <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <div class="input-container position-relative">
                            <v-select class="form-control-field" @input="getAddressList(selectedPostcode)"
                                name="postcode" type="postcode" :options="postcodeOptions" v-model="selectedPostcode"
                                placeholder="Type to search or select" @search="debouncedSearch" :loading="isLoading" required/>
                        </div>
                    </div>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <div class="text-field w-100">
                        <b-row v-if="!isAddingAddressManually" class="mb-6">
                            <Label style="padding-left: 15px; font-weight: bolder;">Address <span style="color: red;">*</span></Label>
                        </b-row>

                        <div v-if="!isAddingAddressManually" class="text-field w-100 selectedAddress">
                            <div class="input-container position-relative">
                                <v-select class="form-control-field" name="address" type="address"
                                    :options="addressOptions" v-model="selectedAddress" @input="setCity" :clearable="true"
                                    placeholder="Type to search or select" :loading="addressLoading" required />
                            </div>
                            <span v-if="selectedAddress && selectedAddress.label" class="tooltiptext">{{ selectedAddress.label }}</span>
                        </div>

                        <b-row v-if="isAddingAddressManually" class="mb-6">
                            <Label style="padding-left: 15px; font-weight: bolder;">Address line 1 <span style="color: red;">*</span></Label>
                        </b-row>
                        <input v-if="isAddingAddressManually" class="form-control-field" name="addressLineOne" @input="setData"
                            type="text" placeholder="Type your answer here..." v-model="addressLineOne" required/>
                    </div>
                </b-col>

            </b-row>

            <!-- 5th row -->
            <b-row class="mt-4" v-if="isAddingAddressManually">

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Address line 2 <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" name="addressLineTwo" type="text" @input="setData"
                            placeholder="Type your answer here..." v-model="addressLineTwo" required/>
                    </div>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">City <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" name="city" type="text" placeholder="Type your answer here..."
                            v-model="city" @input="setData" required/>
                    </div>
                </b-col>

            </b-row>

            <!-- 6th row -->
            <b-row class="mt-4" v-if="!isAddingAddressManually">
                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">City <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" name="city" type="text" placeholder="Type your answer here..."
                            v-model="city" @input="setData" required/>
                    </div>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Mobile Number <span style="color: red;">*</span></Label>
                    </b-row>

                    <div class="text-field w-100">
                        <input class="form-control-field" name="phone" type="tel" @input="setData"
                            placeholder="Format: [+][Country Code][Number] (e.g., +44 20 7946 0958)" v-model="mobileNumber" required/>
                    </div>
                </b-col>
            </b-row>

            <!-- 7th row -->
            <b-row class="mt-4" v-if="isAddingAddressManually">
                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Country <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" name="country" type="text" @input="setData"
                            placeholder="Type your answer here..." v-model="country" required/>
                    </div>
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6">
                        <Label style="padding-left: 15px; font-weight: bolder;">Mobile Number <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" name="mobileNumber" type="text" @input="setData"
                            placeholder="Type your answer here..." v-model="mobileNumber" required/>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-5">
                <b-col cols="12">
                    <h5>Permissions</h5>
                </b-col>
                <b-col class="col-12 mt-4">
                    <div class="form-check check-lg">
                        <input class="form-check-input my-auto" type="checkbox" id="accessNHSDetails"
                            v-model="isCheckedNHSAccess" @change="setData" required/>

                        <label for="accessNHSDetails" class="form-check-label fw-light my-auto"
                            style="margin-left: 15px; font-weight: bold">
                            Yes, I agree to allow Treat It doctors access to my medical records for better care. <br>
                            <span style="font-weight: lighter; font-size: 12px">Your consent ensures seamless and
                                informed care.</span>
                        </label>
                    </div>
                </b-col>

                <b-col class="col-12 mt-4">
                    <div class="form-check check-lg">
                        <input class="form-check-input my-auto" type="checkbox" id="shareDetailsWithGp"
                            v-model="isCheckedShareDetailedWithGP" @change="setData" />

                        <label for="shareDetailsWithGp" class="form-check-label fw-light my-auto"
                            style="margin-left: 15px; font-weight: bold">
                            Yes, I'm comfortable sharing my GP details and for the Treat It clinic to reach out to my
                            GP. <br>
                            <span style="font-weight: lighter; font-size: 12px">Giving this consent ensures coordinated
                                and comprehensive
                                care for you. Rest assured your details are safe with us. We will never share your
                                details with any 3rd
                                parties.</span>
                        </label>
                    </div>
                </b-col>

                <b-col class="col-12 mt-4">
                    <div class="form-check check-lg">
                        <input class="form-check-input my-auto" type="checkbox" id="marketingconsent"
                            v-model="marketingConsent" @change="setData" />

                        <label for="marketingConsent" class="form-check-label fw-light my-auto"
                            style="margin-left: 15px; font-weight: bold">
                            I consent to receiving special offers and general medical information from Treat-It via Call, SMS, WhatsApp and email. (optional)
                        </label>
                    </div>
                </b-col>
            </b-row>

            <!-- GP Details -->
            <b-row v-if="isCheckedShareDetailedWithGP" class="mt-5">
                <b-col cols="12">
                    <h5>Your GP Details</h5>
                    <div style="font-weight: normal; font-size: 12px">Please provide your GP’s details below</div>
                </b-col>
            </b-row>
            <b-row v-if="isCheckedShareDetailedWithGP" class="mt-4">

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;">GP Post code <span style="color: red;">*</span></Label>

                        <span>
                            <label for="addingManually" class="form-check-label fw-light my-auto pr-2"
                                style="margin-left: 15px; font-weight: normal">
                                Enter details manually
                            </label>
                            <input class="my-auto" type="checkbox" id="addingManually" v-model="gpManual" @input="setData" />
                        </span>
                    </b-row>

                    <div class="text-field w-100" v-if="gpManual">
                        <input class="form-control-field" name="gppostcode" type="text" @input="setData"
                            placeholder="Type your answer here..." v-model="manualgppostcode" required/>
                    </div>

                    <div class="text-field w-100" v-if="!gpManual">
                        <div class="input-container position-relative">
                            <v-select class="form-control-field" @input="getSurgeryList" @selected="getSurgeryList"
                                name="gpPostcode" type="gpPostcode" :options="gpPostcodeOptions"
                                v-model="selectedGPPostcode" placeholder="Type to search or select"
                                @search="gpdebouncedSearch" :loading="isGPLoading" required/>
                        </div>
                    </div>

                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="!gpManual"
                            :class="{ 'disabled': !gpManual }">Practice Name <span style="color: red;">*</span></Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" :disabled="!gpManual" :class="{ 'disabled': !gpManual }" @input="setData"
                            name="practice" type="text" placeholder="Type your answer here..." v-model="practice" required/>
                    </div>
                </b-col>

            </b-row>

            <b-row v-if="isCheckedShareDetailedWithGP" class="mt-4">
                <b-col class="col-12 col-md-12 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="!gpManual"
                            :class="{ 'disabled': !gpManual }">Address Line 1</Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" :disabled="!gpManual" :class="{ 'disabled': !gpManual }" @input="setData"
                            name="gpAddress" type="text" placeholder="Type your answer here..." v-model="gpAddress"/>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="isCheckedShareDetailedWithGP" class="mt-4">
                <b-col class="col-12 col-md-12 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="!gpManual"
                            :class="{ 'disabled': !gpManual }">Address Line 2</Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" :disabled="!gpManual" :class="{ 'disabled': !gpManual }" @input="setData"
                            name="gpAddress2" type="text" placeholder="Type your answer here..." v-model="gpAddress2"/>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="isCheckedShareDetailedWithGP" class="mt-4">

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="!gpManual"
                            :class="{ 'disabled': !gpManual }">Town</Label>
                    </b-row>
                    <input class="form-control-field" :disabled="!gpManual" :class="{ 'disabled': !gpManual }" @input="setData"
                        name="town" type="text" placeholder="Type your answer here..." v-model="gpTown" />
                </b-col>

                <b-col class="col-12 col-md-6 pe-md-6 mb-6 mb-md-0">
                    <b-row class="mb-6 manual-details">
                        <Label style="padding-left: 15px; font-weight: bolder;" :disabled="!gpManual"
                            :class="{ 'disabled': !gpManual }">County</Label>
                    </b-row>
                    <div class="text-field w-100">
                        <input class="form-control-field" :disabled="!gpManual" :class="{ 'disabled': !gpManual }" @input="setData"
                            name="country" type="text" placeholder="Type your answer here..." v-model="gpCountry"/>
                    </div>
                </b-col>

            </b-row>

            <b-row class="mt-4 mb-4 next-btn-parent">
                <b-col class="col-12 col-md-12 pe-md-6 mb-6 mb-md-0">
                    <button class="next-btn" type="submit" :disabled="nextDisabled" :class="{ 'disabled': nextDisabled }">
                        <span v-if="!nextDisabled">Next</span>
                        <span><i v-if="nextDisabled" class="fa fa-spinner fa-spin next-spinner" aria-hidden="true"></i></span>
                    </button>
                </b-col>
            </b-row>
        </form>

        <Spinner :isLoading="emailchecking" :text="loadingText" />

        <b-modal v-model="modalShow" title="Surgery List" hide-footer>
            <div class="surgery-card-container position-relative py-2 px-1"
                v-if="showSurgery && surgeriesList.length > 0">
                <div class="surgery-card-list">
                    <div v-for="(surgery, idx) in surgeriesList" :key="idx"
                        :class="{ 'pb-0': idx + 1 === surgeriesList.length, 'pb-2': idx + 1 !== surgeriesList.length, 'pe-1': true }">
                        <SurgeryCard :title="surgery.name" :phone="surgery.mobile" :address="surgery.displayAddress"
                            :onClick="() => handleSelectSurgery(surgery)" />
                    </div>
                </div>
            </div>
            <div v-if="!showSurgery && surgeriesList.length <= 0" style="width: 40rem; height: 37rem;"> Loading... </div>
        </b-modal>

        <b-modal v-model="showAlert" title="Confirmation" centered @ok="handleYes" @cancel="handleNo">
            <h3>Do you want to continue with the previous patient details?</h3>
            <label style="width: 100%;"><span style="font-weight: bold;">OK: </span>Continue with previous data</label>
            <label style="width: 100%;"><span style="font-weight: bold;">CANCEL: </span>Previous data will be deleted</label>
        </b-modal>

    </div>
</template>

<script>
import addPatientService from '../../services/AddPatientService.vue';
import publicService from '../../services/PublicService.vue';
import { cleanSpaces, getDisplayAddress, getGPContact, isValidEmail, isValidPhone, isValidDateofBirth } from "../../util/dataUtils";
import SurgeryCard from '@/views/Patients/SurgeryCard.vue';
import Spinner from '../../components/common/Spinner.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { datadogLogs } from "@datadog/browser-logs";

export default {
    components: {
        vSelect,
        SurgeryCard,
        Spinner
    },
    data: function () {
        return {
            genderOptions: [
                { label: 'MALE', value: 'male' },
                { label: 'FEMALE', value: 'female' }
            ],
            addressOptions: [
                { value: 'Enter Address Manually', label: 'Enter Address Manually' },
            ],
            postcodeOptions: [],
            gpPostcodeOptions: [],
            firstName: '',
            lastName: '',
            selectedGender: '',
            dateOfBirth: '',
            selectedPostcode: '',
            selectedAddress: '',
            isAddingAddressManually: false,
            addressLoading: false,
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            mobileNumber: '',
            country: '',
            emailInput: '',
            isCheckedNHSAccess: false,
            isCheckedShareDetailedWithGP: false,
            selectedGPPostcode: '',
            practice: '',
            gpAddress: '',
            gpAddress2: '',
            gpTown: '',
            gpCountry: '',
            gpManual: false,
            isLoading: false,
            isGPLoading: false,
            surgeriesList: [],
            showSurgery: false,
            manualgppostcode: '',
            modalShow: false,
            emailchecking: false,
            emailDisabled: false,
            marketingConsent: false,
            patientid: '',
            nextDisabled: false,
            showAlert: false,
            loadingText: "Loading...",
            fnameDisabled: false,
            lnameDisabled: false,
            genderDisabled: false,
            dobDisabled: false
        }
    },
    computed: {
    },

    created() {
    },

    mounted() {
        this.loadData();
    },

    methods: {
        //onsubmit
        //Ticket: CG-319
        //Author: Sarmilan
        next() {
            if(!this.isCheckedShareDetailedWithGP && !this.isAddingAddressManually && this.selectedGender !== '' && this.selectedGender !== null && this.selectedPostcode !== '' && this.selectedAddress !== '' && this.selectedAddress !== null){
                this.validations();
            }
            else if(this.isCheckedShareDetailedWithGP && !this.gpManual && this.selectedGPPostcode !== '' && !this.isAddingAddressManually &&  this.selectedGender !== '' && this.selectedPostcode !== '' && this.selectedAddress !== ''){
                this.validations();
            }
            else {
                if(this.selectedGender == '' ||  this.selectedPostcode == '' || this.selectedAddress == '' || ( this.isCheckedShareDetailedWithGP && this.selectedGPPostcode =='')){
                    this.$awn.alert('Please fill all mandatory fields!');
                }
                else {
                    this.validations();
                }
            }
        },

        // phone, dob validations and final confirmation
        //Ticket: CG-319
        //Author: Sarmilan
        async validations() {
            if(!isValidPhone(this.mobileNumber)){
                this.$awn.alert('Phone number not valid!');
            }
            else if(!isValidDateofBirth(this.dateOfBirth)){
                this.$awn.alert('Patient needs to be 18 or over to join our Treat-It programme!');
            }
            else {
                let eligibility = localStorage.getItem('EligibilityForm') ? localStorage.getItem('EligibilityForm') : [];
                let address = {
                    "city": this.gpTown,
                    "county": this.gpCountry,
                    "line1": this.gpAddress,
                    "line2": this.gpAddress2,
                    "postcode": this.selectedGPPostcode
                }
                let data = {
                    "id": this.patientid,
                    "username": null,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "password": 'Testuser1@',
                    "email": this.emailInput,
                    "phone": this.mobileNumber,
                    "preferences": {
                        "accessNHSdetails": this.isCheckedNHSAccess,
                        "shareDetailsWithGp": this.isCheckedShareDetailedWithGP,
                        "gpPractice": this.practice,
                        "gpAddress": JSON.stringify(address)
                    },
                    "gender": this.selectedGender.label,
                    "title": "NOTPROVIDED",
                    "regStatus": "PERSONAL_INFORMATION",
                    "platform": "GPS",
                    "nhsConsent": false,
                    "scrValidityConfirmation": false,
                    "identifiedByPrescribingPharmacist": false,
                    "address": {
                        "line1": (this.addressLineOne == '' && this.selectedAddress != '') ? this.selectedAddress.address.line1 : this.addressLineOne,
                        "line2": (this.addressLineTwo == '' && this.selectedAddress != '') ? this.selectedAddress.address.line2 : this.addressLineTwo,
                        "city": (this.city == '' && this.selectedAddress != '') ? this.selectedAddress.address.city : this.city,
                        "county": (this.country == '' && this.selectedAddress != '') ? this.selectedAddress.address.county : this.country,
                        "postcode": this.selectedPostcode.value
                    },
                    "dateOfBirth": this.dateOfBirth,
                    "displayName": "  ",
                    "externallyIdentified": false,
                    "alerts": null,
                    "allAlerts": null,
                    "marketingConsent": this.marketingConsent,
                    "eligibilityToken": null,
                    "subscription": null,
                    "eligibilityQuestionnaire": JSON.parse(eligibility),
                    "files": [],
                    "nhsVerified": false,
                    "captchaResponseToken": null,
                    "cannabisCardId": null,
                    "nhsNumber": null,
                    "active": true,
                    "settings": {
                        "INITIAL_CONSULTATION_DONE": "false"
                    }
                }

                let datadog_Data = {
                    firstName: this.firstName ? this.firstName : "",
                    lastName: this.lastName ? this.lastName : "",
                    dob: this.dateOfBirth ? this.dateOfBirth : "",
                    gender: this.selectedGender?.label ? this.selectedGender.label : ""
                }
                datadogLogs.logger.info("Patient creation: " + JSON.stringify(datadog_Data));

                try {
                    this.nextDisabled = true;
                    const response = await publicService.updatePatient(data);

                    if(response.status == 200){
                        this.nextDisabled = false;
                        this.$awn.success('Patient account successfully created.');
                        this.$emit('next-page-clicked', 'booking-appointment');
                    }
                    else if(response.status == 500){
                        this.nextDisabled = false;
                        this.$awn.alert('Account cannot be created! Please check the data.');
                    }
                    else {
                        this.nextDisabled = false;
                        this.$awn.alert('Something went wrong!');
                    }
                } catch (error) {
                    this.nextDisabled = false;
                    this.$awn.alert(error);
                }

            }
        },

        //get address list from given postcode
        //Ticket: CG-319
        //Author: Sarmilan
        async getAddressList(postCode) {
            this.isAddingAddressManually = false;
            this.selectedAddress = "";
            this.addressOptions = [];
            this.addressLoading = true;
            this.setData();
            try {
                if(postCode?.value && postCode?.value !== ""){
                    const response = await publicService.getAddresses(postCode?.value.replace(/\s/g, ''));
                    if (response?.data) {
                        this.addressLoading = false;
                        const addressList = response.data.addresses.map((address) => {
                            const addr = address.split(",");
                            const result = {
                                line1: cleanSpaces(addr[0]),
                                line2: cleanSpaces(
                                    addr[1] + " " + addr[2] + " " + addr[3]
                                ),
                                city: cleanSpaces(addr[5]),
                                county: cleanSpaces(addr[6]),
                                postcode: postCode,
                            };

                            return {
                                value: getDisplayAddress(result),
                                label: getDisplayAddress(result),
                                address: result,
                            };
                        });

                        this.addressOptions = [{ value: "Enter Address Manually", label: "Enter Address Manually" }, ...addressList]
                    }
                    else {
                        this.addressLoading = false;
                        this.postcodeOptions = [];
                    }
                }
                else {
                    this.addressLoading = false;
                }
            } catch (error) {
                this.addressLoading = false;
                console.log(error);
            }
        },

        //Set city from selected address
        //Ticket: CG-319
        //Author: Sarmilan
        setCity() {
            if(this.selectedAddress?.label !== "Enter Address Manually"){
                this.city = this.selectedAddress?.address?.city ? this.selectedAddress?.address?.city : '';
                this.country = this.selectedAddress?.address?.county ? this.selectedAddress?.address?.county : '';
                this.addressLineOne = this.selectedAddress?.address?.line1 ? this.selectedAddress?.address?.line1 : '';
                this.addressLineTwo = this.selectedAddress?.address?.line2 ? this.selectedAddress?.address?.line2 : '';
                this.setData();
            }
        },

        //Get surgery list data
        //Ticket: CG-319
        //Author: Sarmilan
        async getSurgeryList() {
            try {
                if (this.selectedGPPostcode?.value) {
                    this.modalShow = true;
                    this.surgeriesList = [];
                    this.showSurgery = false;
                    const res = await addPatientService.getSurgeries(this.selectedGPPostcode.value.replace(/ /g, ""));

                    if (res.data) {
                        this.showSurgery = true;
                        const surgeries = res.data.value.map((surg) => {
                            let address = {
                                line1: surg.Address1,
                                line2: surg.Address2,
                                county: surg.County,
                                city: surg.City,
                                postcode: surg.Postcode,
                            };

                            return {
                                name: surg.OrganisationName,
                                displayAddress: getDisplayAddress(address),
                                address: address,
                                mobile: getGPContact(surg.Contacts),
                            };
                        });

                        this.surgeriesList = surgeries;
                    }
                    else {
                        this.showSurgery = false;
                        this.gpPostcodeOptions = [];
                    }

                }
            }
            catch (error) {
                this.showSurgery = false;
                this.gpPostcodeOptions = [];
            }
        },

        //search on postcode field
        //Ticket: CG-319
        //Author: Sarmilan
        debouncedSearch(search) {
            this.isLoading = true;
            setTimeout(() => {
                this.getOptions(search);
            }, 300);
        },

        //search on gp postcode field
        //Ticket: CG-319
        //Author: Sarmilan
        gpdebouncedSearch(search) {
            this.isGPLoading = true;
            setTimeout(() => {
                this.getOptions(search);
            }, 300);
        },

        //get postcodes
        //Ticket: CG-319
        //Author: Sarmilan
        async getOptions(searchTerm) {
            try {
                const response = await addPatientService.postcodeAutoComplete(searchTerm);
                if (response?.result) {
                    this.isLoading = false;
                    this.isGPLoading = false;
                    this.postcodeOptions = response.result.map((optn) => ({
                        label: optn,
                        value: optn,
                    }));

                    this.gpPostcodeOptions = response.result.map((optn) => ({
                        label: optn,
                        value: optn,
                    }));
                }
                else {
                    this.isLoading = false;
                    this.isGPLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.isGPLoading = false
                console.log(error.response);
            }
        },

        //Check email already available
        //Ticket: CG-319
        //Author: Sarmilan
        async handleEmailBlur() {
            this.emailchecking = true;
            try {

                if(isValidEmail(this.emailInput)){
                    let eligibility = localStorage.getItem('EligibilityForm') ? localStorage.getItem('EligibilityForm') : [];
                    let temp = {
                        eligibilityQuestionnaire: eligibility ? JSON.parse(eligibility) : [],
                        email: this.emailInput.toLowerCase(),
                        marketingConsent: false,
                        password: "Testuser1@",
                        regStatus: "BASIC",
                        username: this.emailInput,
                    }
                    const response = await publicService.emailCheck(temp);
                    if (response == 401) {
                        this.$awn.success('Email already exists');
                        this.loadingText = "Patient already exists & Retrieving details..."
                        this.existingPatient();
                    }
                    else {
                        this.emailchecking = false;
                        if(typeof response === 'number' && !isNaN(response)){
                            this.emailDisabled = true;
                            this.fnameDisabled = false;
                            this.lnameDisabled = false;
                            this.genderDisabled = false;
                            this.dobDisabled = false;
                            this.patientid = response ? response : '';
                            localStorage.setItem('patientID', response);
                        }
                    }
                }
                else {
                    this.$awn.alert('Email not valid!');
                    this.emailchecking = false;
                    this.fnameDisabled = false;
                    this.lnameDisabled = false;
                    this.genderDisabled = false;
                    this.dobDisabled = false;
                }
                
            } catch (error) {
                this.emailchecking = false;
                this.fnameDisabled = false;
                this.lnameDisabled = false;
                this.genderDisabled = false;
                this.dobDisabled = false;
                console.log("error2: ", error)
            }
        },

        //Existing patient flow
        //Ticket: CG-343
        //Author: Sarmilan
        async existingPatient() {
            this.loadingText = "Retrieving patient details..."
            const response = await publicService.searchByEmail(this.emailInput);

            if(response?.content.length > 0){
                let data = response.content[0];

                let datadog_Data = {
                    firstName: data?.firstName ? data.firstName : "",
                    lastName: data?.lastName ? data.lastName : "",
                    dob: data?.dateOfBirth ? data.dateOfBirth : "",
                    gender: data?.gender ? data.gender : ""
                }
                datadogLogs.logger.info("Patient retrieve: " + JSON.stringify(datadog_Data));

                let gpDetails = data?.preferences?.gpAddress ? JSON.parse(data.preferences.gpAddress) : {};

                this.patientid = data?.id ? data.id : "";
                localStorage.setItem('patientID', this.patientid);

                this.firstName = data?.firstName ? data.firstName : "";
                this.lastName = data?.lastName ? data.lastName : "";
                this.emailInput = data?.email ? data.email : "";
                this.mobileNumber = data?.phone ? data.phone : "";
                this.isCheckedNHSAccess = (data?.preferences?.accessNHSdetails == 'true') ? true : false;
                this.isCheckedShareDetailedWithGP = (data?.preferences?.shareDetailsWithGp == 'true') ? true : false;
                this.practice = data?.preferences?.gpPractice ? data.preferences.gpPractice : "";
                this.gpTown = gpDetails?.city ? gpDetails.city : "";
                this.gpCountry = gpDetails?.county ? gpDetails.county : "";
                this.gpAddress = gpDetails?.line1 ? gpDetails.line1 : "";
                this.gpAddress2 = gpDetails?.line2 ? gpDetails.line2 : "";
                this.selectedGPPostcode = gpDetails?.postcode ? gpDetails.postcode : "";
                this.selectedAddress = (data?.address?.line1 !== "Not Provided") ? {
                    "address": data?.address ? data?.address : {},
                    "label": data?.address ? getDisplayAddress(data.address) : "",
                    "value": data?.address ? getDisplayAddress(data.address) : "",
                } : "";
                this.selectedGender = (data?.gender !== "NOTPROVIDED") ? {
                    "label": data.gender,
                    "value": data.gender,
                } : "";
                this.addressLineOne = (data?.address?.line1 && data?.address?.line1 !== 'Not Provided') ? data.address.line1 : "";
                this.addressLineTwo = (data?.address?.line2 && data?.address?.line2 !== 'Not Provided') ? data.address.line2 : "";
                this.city = (data?.address?.city && data?.address?.city !== 'Not Provided') ? data.address.city : "";
                this.country = data?.address?.county ? data.address.county : "";
                this.selectedPostcode = (data?.address?.postcode !== 'Not Provided') ? {
                    "label": (data?.address?.postcode && data?.address?.postcode !== 'Not Provided') ? data.address.postcode : "",
                    "value": (data?.address?.postcode && data?.address?.postcode !== 'Not Provided') ? data.address.postcode : "",
                } : "";
                this.dateOfBirth = data?.dateOfBirth ? data.dateOfBirth : "";
                this.marketingConsent = data?.marketingConsent ? data.marketingConsent : false;
                this.emailDisabled = true;
                this.emailchecking = false;
                this.fnameDisabled = (this.firstName !== "") ? true : false;
                this.lnameDisabled = (this.lastName !== "") ? true : false;
                this.genderDisabled = this.selectedGender.value ? true : false;
                this.dobDisabled = (this.dateOfBirth !== "") ? true : false;
                this.setData();
            }
            else {
                this.emailchecking = false;
                this.$awn.alert("We couldn't find any data for given email! Please check the email.");
            }
        },

        //Surgery list value selection
        //Ticket: CG-319
        //Author: Sarmilan
        handleSelectSurgery(surgery) {
            this.closeModal();
            this.selectedGPPostcode = surgery?.address?.postcode ? surgery.address.postcode : "";
            this.manualgppostcode = surgery?.address?.postcode ? surgery.address.postcode : "";
            this.practice = surgery?.name ? surgery.name : "";
            this.gpAddress = surgery?.address?.line1 ? surgery.address.line1 : "";
            this.gpAddress2 = surgery?.address?.line2 ? surgery.address.line2 : "";
            this.gpTown = surgery?.address?.city ? surgery.address.city : "";
            this.gpCountry = surgery?.address?.county ? surgery.address.county : "";
            this.gpManual = true;
            this.setData();
        },

        //Close surgery list modal
        //Ticket: CG-319
        //Author: Sarmilan
        closeModal() {
            this.modalShow = false;
        },

        //Set data to localstorage
        //Ticket: CG-319
        //Author: Sarmilan
        setData() {
            let data = {
                "firstName": this.firstName ? this.firstName : '',
                "lastName": this.lastName ? this.lastName : '',
                "emailInput": this.emailInput ? this.emailInput : '',
                "mobileNumber": this.mobileNumber ? this.mobileNumber : '',
                "isCheckedNHSAccess": this.isCheckedNHSAccess ? this.isCheckedNHSAccess : false,
                "isCheckedShareDetailedWithGP": this.isCheckedShareDetailedWithGP ? this.isCheckedShareDetailedWithGP : false,
                "practice": this.practice ? this.practice : '',
                "gpTown": this.gpTown ? this.gpTown : '',
                "gpCountry": this.gpCountry ? this.gpCountry : '',
                "gpAddress": this.gpAddress ? this.gpAddress : '',
                "gpAddress2": this.gpAddress2 ? this.gpAddress2 : '',
                "selectedGPPostcode": this.selectedGPPostcode ? this.selectedGPPostcode : '',
                "selectedAddress": this.selectedAddress ? this.selectedAddress : '',
                "selectedGender": this.selectedGender?.label ? this.selectedGender?.label : '',
                "addressLineOne": (this.addressLineOne == '' && this.selectedAddress != '' && this.selectedAddress?.address?.line1) ? this.selectedAddress.address.line1 : this.addressLineOne,
                "addressLineTwo": (this.addressLineTwo == '' && this.selectedAddress != '' && this.selectedAddress?.address?.line2) ? this.selectedAddress.address.line2 : this.addressLineTwo,
                "city": (this.city == '' && this.selectedAddress != '') ? this.selectedAddress?.address?.city : this.city,
                "country": (this.country == '' && this.selectedAddress != '') ? this.selectedAddress?.address?.county : this.country,
                "selectedPostcode": this.selectedPostcode?.value ? this.selectedPostcode?.value : '',
                "dateOfBirth": this.dateOfBirth ? this.dateOfBirth : '',
                "marketingConsent": this.marketingConsent ? this.marketingConsent : false,
            }
            localStorage.setItem("userData", JSON.stringify(data));
        },

        //Load data from localstorage at start if available
        //Ticket: CG-319
        //Author: Sarmilan
        loadData() {
            let id = localStorage.getItem('patientID');
            this.patientid = JSON.parse(id);

            if(id){
                this.showAlert = true;
            }
            else {
                this.showAlert = false;
            }
        },

        async handleYes(){
            this.showAlert = false;
            let userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;

            if(userData){
                let jsonData = JSON.parse(userData);
                for (let key in jsonData) {
                    if (typeof this[key] !== 'undefined') {
                        if(key === 'selectedGender' || key === 'selectedPostcode'){
                            if(jsonData[key] !== ""){
                                this[key] = {
                                    label: jsonData[key],
                                    value: jsonData[key]
                                };
                            }
                            else {
                                this[key] = "";
                            }
                        }
                        else if(key === 'emailInput') {
                            this[key] = jsonData[key];
                            if(isValidEmail(jsonData[key])){
                                this.emailDisabled = (jsonData[key] !== '') ? true : false;
                                const response = await publicService.searchByEmail(jsonData[key]);
                                if(response?.content[0]?.regStatus == "COMPLETE"){
                                    this.fnameDisabled = true;
                                    this.lnameDisabled = true;
                                    this.genderDisabled = true;
                                    this.dobDisabled = true;
                                }
                            }
                        }
                        else {
                            this[key] = jsonData[key];
                        }
                    }
                }
            }
            else {
                this.$awn.alert("Sorry, we couldn't find any data!");
            }

        },

        handleNo() {
            this.showAlert = false;
            localStorage.removeItem('userData');
            localStorage.removeItem('patientID');
        }
    },
    watch: {
        //Tracking if manual address was selected
        //Ticket: CG-319
        //Author: Sarmilan
        selectedAddress(addressSelected) {
            if (addressSelected?.value === "Enter Address Manually") {
                this.isAddingAddressManually = true;
            }
        },
    }
}

</script>

<style scoped lang="scss">
    .form-check {
        display: flex;

        .form-check-input {
            min-width: 1em;
            min-height: 1em;
        }

        .form-check-label {
            margin-left: 0.5rem;
        }
    }

    .check-lg {
        &.form-check {
            .form-check-input {
                min-width: 1.5rem;
                height: 1.5rem;
                border-radius: unset;
                background-size: 14px;
            }
        }
    }

    .form-check-input:disabled {
        opacity: 1;

        &~.form-check-label {
            opacity: 1;
        }
    }

    .form-control-field {
        background-color: #f0f3f5;
        border: 2px solid #e4e5e6;
        border-radius: 6px;
        font-size: 1rem;
        max-height: 3.25rem;
        padding: 10px 24px;
        width: 100%;
    }

    .manual-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 15px;
    }

    .next-btn-parent {
        text-align: center;
    }

    .next-btn {
        background: #550385;
        color: #fff;
        border-radius: 6px !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        padding: 10px 30px !important;
        border: 0;
        width: 150px;
        height: 50px;
    }

    .next-spinner {
        color: #fff !important;
        position: relative !important;
        right: unset !important;
        top: unset !important;
    }

    .check-btn {
        background: transparent;
        border: 1px solid #805ad5;
        color: #000;
        border-radius: 6px !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        padding: 10px 30px !important;
    }

    .disabled {
        opacity: 0.6;
    }

    .surgery-card-container {
        max-height: 37rem;
        background: transparent;
        border-radius: 0 0 0.5rem 0.5rem;
        z-index: 1000;
        min-width: 40rem;
        margin-bottom: 10px;

        .surgery-card-list {
            max-height: 37rem;
            min-width: 40rem;
            overflow: auto;
        }
    }

    .fa-spinner {
        position: absolute;
        right: 30px;
        top: 55%;
        font-size: 20px;
    }

    .fa-check {
        position: absolute;
        right: 30px;
        top: 55%;
        font-size: 20px;
        color: green;
    }

    .input {
        color: black;
        caret-color: black;
    }

    .selectedAddress .tooltiptext {
        visibility: hidden;
        width: auto;
        top: 100%;
        left: 20%;
        margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
        background-color: #536c79;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
    }

    .selectedAddress:hover .tooltiptext {
        visibility: visible;
    }

    ::v-deep .vs__selected-options {
        white-space: nowrap;
        overflow: hidden;
        margin-right: 10px;
    }

    ::v-deep .vs__selected-options {
        display: flex;
        flex-basis: 100%;
        flex-grow: 1;
        flex-wrap: unset;
        padding: 0 2px;
        position: relative;
    }

    ::v-deep .modal-content {
        width: fit-content !important;
    }

    ::v-deep .vs__dropdown-toggle {
        background: transparent !important;
        border: 0 !important;
        padding: 0 !important;
    }

    ::v-deep .vs__dropdown-option--highlight {
        background: #805ad5
    }

    ::v-deep .modal-body {
        background: #f8f8f8;
    }
</style>